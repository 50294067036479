<template>
  <div>
    <!-- MAIN LOADER -->
    <template v-if="loader && !campaign">
        <Loader
          :size="'big'"
        />
    </template> <!-- END MAIN LOADER -->
    <template v-else-if="currentPackage">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center mb-5">
        <div class="col-lg-7">
          <h1 class="highlighted-color1 font-size-large wrap-text">
            <span>{{ currentPackage.name }}</span>
          </h1>
        </div>
        <div class="d-flex">
          <template v-if="!campaign.is_read_only">
            <div id="edit-package-wrap">
              <router-link
                :to="{ name: 'packages.edit', params: { cId: campaign.id, id: currentPackage.id } }"
                class="btn btn-sm btn-primary me-2"
                :class="{
                  disabled: isCampaignPaused
                }"
                v-if="campaign.status !== 'FINISHED'"
              >
                {{ $t('edit_group') }}
              </router-link>
            </div>
            <b-tooltip
              target="edit-package-wrap"
              v-if="isCampaignPaused"
            >
              {{ $t('editing_a_campaign_group_is_not_allowed') }}
            </b-tooltip>
            <template v-if="campaign.status !== 'FINISHED'">
              <template v-if="currentPackage.status === 'ACTIVE'">
                <Button
                  id="pause-package-button"
                  class="btn-sm btn-color1 me-2"
                  @click="openPauseCampaignPackageModal"
                  :softDisabled="isCampaignPaused"
                >
                  {{ $t('pause') }}
                </Button>
                <b-tooltip
                  target="pause-package-button"
                  v-if="isCampaignPaused"
                >
                  {{ $t('not_allowed_to_pause_a_campaign_group') }}
                </b-tooltip>
                <PauseCampaignPackageModal
                  :campaign="campaign"
                  :segmentation="currentPackage"
                  @package-paused="getCampaign"
                />
              </template>
              <template v-else-if="currentPackage.status === 'PAUSED'">
                <Button
                  id="activate-package-button"
                  class="btn-sm btn-color1 me-2"
                  @click="openActivateCampaignPackageModal"
                  :softDisabled="isCampaignPaused"
                >
                  {{ $t('enable') }}
                </Button>
                <b-tooltip
                  target="activate-package-button"
                  v-if="isCampaignPaused"
                >
                  {{ $t('not_allowed_to_pause_a_campaign_group') }}
                </b-tooltip>
                <ActivateCampaignPackageModal
                  :campaign="campaign"
                  :segmentation="currentPackage"
                  @package-activated="getCampaign"
                />
              </template>
            </template>
          </template>
          <router-link
            :to="{ name: 'campaigns.show', params: { id: campaign.id } }"
            class="btn btn-sm btn-primary"
          >
            {{ $t('back') }}
          </router-link>
        </div>
      </div> <!-- END HEADER -->
      <!-- MESSAGES -->
      <div
        class="mb-4"
        v-if="currentPackage.status === 'ERROR' || currentPackage.status === 'PAUSED'"
      >
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentPackage.status === 'ERROR'"
        >
          <p
            class="mb-0"
          >
            {{ $t('error_creating_campaign_group') }}
            <span>
              <RetryCampaignPackageCreationOnPlatformButton
                :packageId="currentPackage.id"
                @retry-successful="getCampaign"
                @retry-error="handleRetryError"
                color="white"
              />
            </span>
          </p>
        </div>
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentPackage.status === 'PAUSED'"
        >
          <p
            class="mb-0"
          >
            {{ $t('this_campaign_group_is_paused') }}
          </p>
        </div>
      </div> <!-- END MESSAGES -->
      <!-- OVERVIEW -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium mb-4">
          <span>{{ $t('general_data') }}</span>
        </h2>
        <div class="card">
          <div class="card-body">
            <p>
              <strong>{{ $t('name') }}:</strong> {{ currentPackage.name }}<br/>
              <strong>{{ $t('status') }}:</strong> {{ currentPackage.status | formatCampaignStatus }}<br/>
              <strong>{{ $t('spending_to_date') }}:</strong> {{ currentPackage.spending / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('total_impressions') }}:</strong> {{ currentPackage.impressions | formatNumber }}<br/>
              <strong>{{ $t('total_clicks') }}:</strong> {{ currentPackage.clicks | formatNumber }}<br/>
              <strong>{{ $t('total_conversions') }}:</strong> {{ currentPackage.conversions | formatNumber }}<br/>
              <strong>{{ $t('ctr') }}:</strong> {{ currentPackage.ctr | formatNumber }}%<br/>
              <strong>{{ $t('cpm') }}:</strong> {{ currentPackage.cpm / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('cpc') }}:</strong> {{ currentPackage.cpa / 100 | formatMoney(campaign.advertiser.currency) }}
            </p>
          </div>
        </div>
      </div> <!-- END OVERVIEW -->
      <!-- ADS -->
      <div class="mt-4">
        <!-- HEADER -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2 class="highlighted-color1">
              <span>{{ $t('advertisements') }}</span>
            </h2>
          </div>
        </div> <!-- END HEADER -->
        <CreativeListTable
          :creatives="creatives"
        />
      </div> <!-- END ADS -->
    </template>
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import variables from '@/mixins/variables'

import ActivateCampaignPackageModal from '@/components/campaigns/ActivateCampaignPackageModal'
import Button from '@/components/common/Button/Button'
import CreativeListTable from '@/components/creatives/CreativeListTable'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignPackageModal from '@/components/campaigns/PauseCampaignPackageModal'
import RetryCampaignPackageCreationOnPlatformButton from '@/components/campaigns/RetryCampaignPackageCreationOnPlatformButton'

export default {
  mixins: [variables],
  components: {
    ActivateCampaignPackageModal,
    Button,
    CreativeListTable,
    Loader,
    PauseCampaignPackageModal,
    RetryCampaignPackageCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.currentPackage ? this.currentPackage.name : ''
    }
  },
  created () {
    if (this.storeCampaign) {
      this.campaign = this.storeCampaign
    } else this.getCampaign()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      storeCampaign: state => state.currentCampaign
    }),
    /**
     * Creatives
    */
    creatives () {
      return this.currentPackage.lines[0].creatives
    },
    currentPackage () {
      if (!this.campaign) return null
      return this.campaign.packages.find(item => {
        return item.id === this.$route.params.id
      })
    },
    /**
     * Is campaign paused
     */
    isCampaignPaused () {
      if (!this.campaign) return false
      return this.campaign.status === 'PAUSED'
    },
    /**
     * Keywords
    */
    keywords () {
      return this.currentPackage.lines[0].keywords
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get campaign
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.cId)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Open activate campaign package modal
     */
    openActivateCampaignPackageModal () {
      this.$bvModal.show('activate-campaign-package-modal')
    },
    /**
     * Open pause campaign package modal
     */
    openPauseCampaignPackageModal () {
      this.$bvModal.show('pause-campaign-package-modal')
    },
    /**
     * Open replicate line modal
     */
    openReplicateLineModal (line) {}
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
